<template>
  <div class="InputLabel">
    <div class="InputLabelText">{{ label }}</div>
    <div class="InputLabelRequired" v-if="required">{{ $locale["required"] }}</div>
  </div>
</template>

<script>
export default {
  props: ["label", "required"],
};
</script>

<style lang="scss" scoped>
.InputLabel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $mpadding/1.5 $mpadding;
  font-size: 90%;
  opacity: 0.7;
  font-family: $third_font_bold;
  .InputLabelRequired {
    display: inline-block;
    background-color: $button_contrast;
    padding: $mpadding/6 $mpadding/2;
    border-radius: 2pc;
    font-size: 80%;
    user-select: none;
  }
  .InputLabelText {
    font-size: 90%;
  }
}
</style>
